import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const Navrouter = () => {
  return (
    <div>
      <Navbar expand="lg" className="navbar-custom"> {/* Apply custom class */}
        <Container>
          <Navbar.Brand>Admin Panel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link to='/view-products' className="nav-link">View Products</Link> {/* Add nav-link class */}
              <Link to='/add-product' className="nav-link">Add Product</Link> {/* Add nav-link class */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navrouter;
