import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components

const ViewProduct = () => {
  const [products, setProducts] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [updatedProductName, setUpdatedProductName] = useState('');
  const [updatedProductImage, setUpdatedProductImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch products from the backend
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://apihygine.shreedigimarketing.in/api/products');
      setProducts(response.data.data); // Assuming products come in response.data.data
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://apihygine.shreedigimarketing.in/api/deleteproduct/${id}`);
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Handle edit (setting the product to be edited)
  const handleEdit = (product) => {
    setEditProduct(product.id);
    setUpdatedProductName(product.productname);
    setShowModal(true); // Show the modal when editing
  };

  // Handle update product
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append('productname', updatedProductName);
    
    // Only append image if the user selected a new one
    if (updatedProductImage) {
      formData.append('productimage', updatedProductImage);
    }

    try {
      await axios.put(`https://apihygine.shreedigimarketing.in/api/updateproduct/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setEditProduct(null);
      setShowModal(false); // Close the modal
      fetchProducts(); // Re-fetch products after update
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  // Handle closing the modal
  const handleClose = () => {
    setShowModal(false);
    setEditProduct(null);
    setUpdatedProductName('');
    setUpdatedProductImage(null); // Reset the states when modal closes
  };

  return (
    <div className="product-list">
      <h2>Products List</h2>
      {products.length > 0 ? (
        <div className="card-container">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              {product.productimage && (
                <img
                  src={`https://apihygine.shreedigimarketing.in${product.productimage}`}
                  alt={product.productname}
                  className="product-image"
                />
              )}
              <strong>{product.productname}</strong>
              <Button className="edit-button" onClick={() => handleEdit(product)}>
                Edit
              </Button>
              <Button className="delete-button" onClick={() => handleDelete(product.id)}>
                Delete
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <p>No products found</p>
      )}

      {/* Modal for Editing Product */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={updatedProductName}
            onChange={(e) => setUpdatedProductName(e.target.value)}
            placeholder="Product Name"
            className="edit-input"
          />
          <input
            type="file"
            onChange={(e) => setUpdatedProductImage(e.target.files[0])}
            className="edit-input"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdate(editProduct)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewProduct;
