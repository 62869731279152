import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddProduct from './AddProduct';
import Navrouter from './Navrouter';
import ViewProduct from './viewproduct';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const App = () => {
  return (
    <Router>
    <Navrouter/>
      <Routes>
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/view-products" element={<ViewProduct />} />
      </Routes>
    </Router>
  );
};

export default App;
